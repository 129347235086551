.button_wrapper {
  width: 100%;

  .button {
    width: 100%;
    justify-content: flex-start;

    .close_button_text_wrapper {
      width: 100%;
      color: #222229;
      padding-top: 21px;
      border-top: 1px solid #F0F0F2;

      .close_button_text {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.card_number {
  width: 276px;
  @media screen and (max-width: 648px) {
    width: auto;
  }
}

.date {
  width: 156px;
  > div {
    > div {
      padding: 0;
      min-width: 0;
      flex-grow: 0;
    }
  }
  @media screen and (max-width: 648px) {
    width: auto;
  }
}

.cardholder {
  width: 464px;
}
