.tips_popup {
  position: relative;
  .tips_message {
    bottom: 35px;
    left: -102px;
  }
  .tips_message_rotate {
    top: 35px;
    left: -102px;
    > :last-child {
      top: -46px;
      rotate: 225deg;
    }
  }

  .tips-preline {
    white-space: pre-line;
  }
}

.container {
  display: flex;
}

.popup-message {
  margin-left: 4px;

  > :first-child {
    position: absolute;
  }
  > :last-child {
    position: absolute;
    cursor: pointer;
  }
}

.icon-box {
  @media (max-width: 1279px) {
    svg path {
      fill: #2d48ed;
    }
  }
}
