* {
  margin: 0;
  padding: 0;
}

body {
  overflow-y: scroll;
}

html,
body {
  font-family: 'Commissioner-Regular';
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  z-index: 1;
  position: relative;
}

#modals {
  z-index: 2;
  position: relative;
}

#toasts {
  z-index: 3;
  position: relative;
}

.App {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.spinner {
  box-sizing: border-box;
  padding: 0 !important;
  margin: 0 !important;
}

@font-face {
  font-family: 'Commissioner-Balck';
  src: url('./assets/fonts/Commissioner-Black.ttf');
}

@font-face {
  font-family: 'Commissioner-Bold';
  src: url('./assets/fonts/Commissioner-Bold.ttf');
}

@font-face {
  font-family: 'Commissioner-ExtraBold';
  src: url('./assets/fonts/Commissioner-ExtraBold.ttf');
}

@font-face {
  font-family: 'Commissioner-ExtraLight';
  src: url('./assets/fonts/Commissioner-ExtraLight.ttf');
}

@font-face {
  font-family: 'Commissioner-Light';
  src: url('./assets/fonts/Commissioner-Light.ttf');
}

@font-face {
  font-family: 'Commissioner-Medium';
  src: url('./assets/fonts/Commissioner-Medium.ttf');
}

@font-face {
  font-family: 'Commissioner-Regular';
  src: url('./assets/fonts/Commissioner-Regular.ttf');
}

@font-face {
  font-family: 'Commissioner-SemiBold';
  src: url('./assets/fonts/Commissioner-SemiBold.ttf');
}

@font-face {
  font-family: 'Commissioner-Thin';
  src: url('./assets/fonts/Commissioner-Thin.ttf');
}

@font-face {
  font-family: 'Lato-Balck';
  src: url('./assets/fonts/Lato-Black.ttf');
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('./assets/fonts/Lato-Bold.ttf');
}

@font-face {
  font-family: 'Lato-Blak-Italic';
  src: url('./assets/fonts/Lato-BlackItalic.ttf');
}

@font-face {
  font-family: 'Lato-Bold-Italic';
  src: url('./assets/fonts/Lato-BoldItalic.ttf');
}

@font-face {
  font-family: 'Lato-Italic';
  src: url('./assets/fonts/Lato-Italic.ttf');
}

@font-face {
  font-family: 'Lato-Light';
  src: url('./assets/fonts/Lato-Light.ttf');
}

@font-face {
  font-family: 'Lato-Light-Italic';
  src: url('./assets/fonts/Lato-LightItalic.ttf');
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('./assets/fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato-Thin';
  src: url('./assets/fonts/Lato-Thin.ttf');
}

@font-face {
  font-family: 'Lato-Thin-Italic';
  src: url('./assets/fonts/Lato-ThinItalic.ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf');
}
