@import '../../../styles/values/breakpoints.scss';

.page {
  width: 100%;
  height: 100%;
  min-height: fit-content;
}

.container {
  position: relative;
  margin: 48px auto 68px;
  width: 100%;
  width: 600px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;

  @media (max-width: $breakpoint-lg) {
    width: auto;
    margin: 24px auto 0;
    padding: 0 24px 20px;
  }

  @media (max-width: $breakpoint-md) {
    padding: 0 16px 20px;
  }
}
