.image_wrapper {
  margin-left: 12px;
}

.toast_wrapper {
  align-items: baseline;
  margin-bottom: 12px;
}

.form_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
