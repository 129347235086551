.label {
  pointer-events: none;
  position: absolute;
  top: 24px;
  left: 18px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  box-sizing: border-box;
}

.on-focus {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  position: relative;
  font-size: 13px;
  color: #4c4e57;
  top: -18px;
}
