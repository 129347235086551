.body {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

.row {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #e5e7eb;
  margin-top: 12px;
  padding: 8px 0;
  align-items: flex-end;
}

.field {
  display: flex;
  justify-content: flex-start;

  &:first-child {
    max-width: 120px;
    width: 100%;
    padding-right: 12px;
  }

  &:last-child {
    flex: 1;

    * {
      color: #6e6f78;
    }
  }
}

.form {
  margin: 12px 0 24px;
}

.form_title {
  margin-top: 24px;
}

.buttons {
  margin: 24px 0 0;
  display: flex;
  justify-content: space-between;

  button {
    white-space: nowrap;
  }
  > :first-child {
    margin: 0;
  }
  > :last-child {
    margin: 0;
    border: none;
  }
  @media screen and (max-width: 648px) {
    width: 100%;
  }
}

.edit {
  position: relative;
}

.edit-button {
  position: absolute;
  right: -25px;
  top: -10px;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  z-index: 111;
  background-color: #eee;
  border-radius: 2px;
  line-height: 1;
  padding: 2px 4px;
  font-weight: 100;
  pointer-events: all;
  width: auto !important;
  height: auto !important;

  &:hover {
    color: #2d48ed;
  }
}

.edit-toggle {
  position: fixed;
  left: 24px;
  bottom: 24px;
  z-index: 999;
}
