.history_popup {
  position: relative;
  .history_message {
    bottom: 35px;
    left: -102px;
  }
  .history_message_rotate {
    top: 35px;
    left: -102px;
    > :last-child {
      top: -46px;
      rotate: 225deg;
    }
  }
}

.break {
  white-space: break-spaces;

  @media (max-width: 768px) {
    white-space: inherit;
  }
}

.notLoading{
  @media (min-width: 1280px) {
    position: absolute;
    top: -9px;
    left: 20px;
  }

}

.loading {
  @media (min-width: 1280px) {
    position: absolute;
    top: -9px;
    left: 20px;
  }
}

.loading, .notLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
}

.loading {
  animation-duration: 1s;
  animation: spin 2s linear infinite;
  opacity: 0.5;

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
