@import '../../styles/values/color.scss';
@import '../../styles/values/breakpoints.scss';

.field {
  width: 50%;

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.title {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  gap: 8px;
}

.subtitle {
  // TODO: Remove important after refactor TextField component
  color: var(--neutral-700) !important;
}

.swap {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
  box-sizing: border-box;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin: 16px 0px;

  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
  }
}

.rate {
  color: #4c4e57;
}

.input_container {
  display: flex;
  flex-direction: column;
  width: 50%;

  input {
    margin-top: 9px;
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
}
