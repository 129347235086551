.splash {
  width: 100vw;
  height: 100vh;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
}

.message_block {
  max-width: 480px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error_message {
  color: #ff5c5c !important;
}
