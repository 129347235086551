.masked_input {
  border: none;
  background: transparent;
  width: 80%;
  font-family: "Lato-Regular";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222229;


  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &:focus {
    outline: none;
  }
}
